import React, {useState, useEffect, FC} from 'react';
import {useMutation} from '@apollo/react-hooks';
import {navigate} from 'gatsby';
import {SUBMIT_ENTERPRISE} from '../../graphql/mutations';

import {isValidUrl, isValidEmail, isValidPhoneNumber, isValidDomain} from '../../helpers/constants';
import {thankYou} from '../../images/svgIcons';
import Message from '../messages';
import Button from '../button';
import Select from '../../components/select';

import './styles.scss';

export const options: ReadonlyArray<{label: string; value: string}> = [
  {label: '1-10', value: '1-10'},
  {label: '11-50', value: '11-50'},
  {label: '51-100', value: '51-100'},
  {label: '101-200', value: '101-200'},
  {label: '201-500', value: '201-500'},
  {label: '500+', value: '500+'},
];

interface EnterpriseFormProps {
  onCLick?: () => void;
  setShowSentMsg: (arg: boolean) => void;
}

const EnterpriseForm: FC<EnterpriseFormProps> = ({onCLick, setShowSentMsg}) => {
  const [submitEnterprise, {loading}] = useMutation(SUBMIT_ENTERPRISE);

  const [companySize, setCompanySize] = useState({label: 'Please select one', value: ''});
  const [showErrorMsg, setShowErrorMsg] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [success, setSuccess] = useState(false);
  const [invalidValues, setInvalidValues] = useState({
    workEmail: false,
    phoneNumber: false,
    website: false,
  });

  const [formData, setFormData] = useState<{
    [arg: string]: string;
  }>({
    firstName: '',
    lastName: '',
    workEmail: '',
    phoneNumber: '',
    companyName: '',
    jobTitle: '',
    website: '',
    message: '',
    companySize: '',
    productType: 'hexowatch',
  });

  const fieldChangeHandler = (event: React.SyntheticEvent): void => {
    const target = event.target as HTMLInputElement;
    const {value, name} = target;

    setFormData({
      ...formData,
      [name]: value,
    });
    if ((name === 'workEmail' || name === 'phoneNumber' || name === 'website') && value) {
      setErrorMsg('');
      setShowErrorMsg(false);
      setInvalidValues({...invalidValues, [name]: false});
    }
  };

  useEffect(() => {
    setFormData({...formData, companySize: companySize.value});
  }, [companySize]);

  const handleSubmit = async (): Promise<any> => {
    setShowErrorMsg(false);
    if (Object.values(formData).filter((item: any) => !item.trim()).length > 0) {
      setShowErrorMsg(true);
      setErrorMsg('Please fill out all the required fields');
      return;
    }

    if (!isValidEmail(formData.workEmail.trim())) {
      setErrorMsg('Work email is not valid');
      setShowErrorMsg(true);
      setInvalidValues({phoneNumber: false, website: false, workEmail: true});
      return;
    }

    if (!isValidPhoneNumber(formData.phoneNumber.trim())) {
      setErrorMsg('Phone number is not valid');
      setShowErrorMsg(true);
      setInvalidValues({workEmail: false, website: false, phoneNumber: true});
      return;
    }
    if (!isValidUrl(formData.website.trim()) || !isValidDomain(formData.website.trim())) {
      setErrorMsg('Website URL is not valid');
      setShowErrorMsg(true);
      setInvalidValues({workEmail: false, phoneNumber: false, website: true});
      return;
    }

    const newFormData = {...formData};

    Object.keys(newFormData).forEach((k: string) => (newFormData[k] = newFormData[k].trim()));

    const {data} = await submitEnterprise({
      variables: {settings: {...newFormData}},
    });

    const {error, message} = data?.EnterpriseSectionOps?.sendEmail;
    if (error) {
      setErrorMsg(message);
      setShowErrorMsg(true);
      return;
    }
    // setShowSentMsg(true);
    // onCLick && onCLick();
    setSuccess(true);
  };

  return (
    <div className="enterprise-block">
      {success ? (
        <div className="text-center">
          <div className="success-icon">{thankYou}</div>
          <p className="my-3">Your message has been successfully sent!</p>
          <Button onClick={() => navigate('/')}>Go to home page</Button>
        </div>
      ) : (
        <>
          <h4 className="title">Fill out the form below and we’ll get in touch</h4>

          <div className="forms">
            <div className="wrapper">
              <div className="form-item">
                <label htmlFor="firstName">
                  First name <span>*</span>
                </label>
                <input
                  style={{border: showErrorMsg && !formData.firstName.trim() ? '1px solid #fb6f6f' : ''}}
                  required
                  placeholder="Your first name"
                  type="text"
                  name="firstName"
                  id="firstName"
                  onChange={fieldChangeHandler}
                  value={formData.firstName}
                  maxLength={255}
                />
              </div>

              <div className="form-item">
                <label htmlFor="lastName">
                  Last name <span>*</span>
                </label>

                <input
                  style={{border: showErrorMsg && !formData.lastName.trim() ? '1px solid #fb6f6f' : ''}}
                  required
                  placeholder="Your last name"
                  type="text"
                  name="lastName"
                  id="lastName"
                  onChange={fieldChangeHandler}
                  value={formData.lastName}
                  maxLength={255}
                />
              </div>
            </div>

            <div className="wrapper">
              <div className="form-item">
                <label htmlFor="workEmail">
                  Work email <span>*</span>
                </label>
                <input
                  style={{
                    border:
                      (showErrorMsg && !formData.workEmail.trim()) || (showErrorMsg && invalidValues.workEmail)
                        ? '1px solid #fb6f6f'
                        : '',
                  }}
                  required
                  placeholder="example@company.com"
                  type="email"
                  name="workEmail"
                  id="workEmail"
                  onChange={fieldChangeHandler}
                  value={formData.workEmail}
                  maxLength={320}
                />
              </div>
              <div className="form-item">
                <label htmlFor="phoneNumber">
                  Phone number <span>*</span>
                </label>
                <input
                  style={{
                    border:
                      (showErrorMsg && !formData.phoneNumber.trim()) || (showErrorMsg && invalidValues.phoneNumber)
                        ? '1px solid #fb6f6f'
                        : '',
                  }}
                  required
                  placeholder="(555) 123-1234"
                  type="tel"
                  name="phoneNumber"
                  id="phoneNumber"
                  onChange={fieldChangeHandler}
                  value={formData.phoneNumber}
                  maxLength={255}
                />
              </div>
            </div>

            <div className="wrapper">
              <div className="form-item">
                <label htmlFor="companyName">
                  Company name <span>*</span>
                </label>

                <input
                  style={{border: showErrorMsg && !formData.companyName.trim() ? '1px solid #fb6f6f' : ''}}
                  required
                  placeholder="Company name"
                  type="text"
                  name="companyName"
                  id="companyName"
                  onChange={fieldChangeHandler}
                  value={formData.companyName}
                  maxLength={255}
                />
              </div>
              <div className="form-item">
                <label htmlFor="website">
                  Website <span>*</span>
                </label>
                <input
                  style={{
                    border:
                      (showErrorMsg && !formData.website.trim()) || (showErrorMsg && invalidValues.website)
                        ? '1px solid #fb6f6f'
                        : '',
                  }}
                  required
                  placeholder="company.com"
                  type="url"
                  name="website"
                  id="website"
                  onChange={fieldChangeHandler}
                  value={formData.website}
                  maxLength={255}
                />
              </div>
            </div>

            <div className="d-flex wrapper-fields justify-content-between align-items-center">
              <div style={{width: '48%'}} className="form-item">
                <label>
                  Company size <span>*</span>
                </label>
                <Select
                  className={`${showErrorMsg && !formData.companySize ? 'brd-err' : ''} `}
                  options={options}
                  value={companySize}
                  onChange={setCompanySize}
                />
              </div>

              <div style={{width: '48%'}} className="form-item">
                <label htmlFor="jobTitle">
                  Job title <span>*</span>
                </label>
                <input
                  style={{
                    border: showErrorMsg && !formData.jobTitle.trim() ? '1px solid #fb6f6f' : '',
                  }}
                  required
                  placeholder="Director"
                  type="text"
                  name="jobTitle"
                  id="jobTitle"
                  onChange={fieldChangeHandler}
                  value={formData.jobTitle}
                  maxLength={255}
                />
              </div>
            </div>

            <div className="d-flex align-items-start flex-column w-100">
              <label className="textarea-label" htmlFor="message">
                Message <span>*</span>
              </label>
              <textarea
                style={{
                  border: showErrorMsg && !formData.message.trim() ? '1px solid #fb6f6f' : '',
                }}
                value={formData.message}
                maxLength={2000}
                onChange={fieldChangeHandler}
                name="message"
                id="message"
                placeholder="Please provide some details on project or use-case you want to implement on Hexowatch"
                cols={30}
                rows={10}
                required
              ></textarea>
            </div>

            {showErrorMsg && (
              <div className="mt-2">
                <Message type="error" text={errorMsg || 'Please fill out all the required fields'} />
              </div>
            )}
          </div>

          <div className="submit-block">
            <Button loading={loading} primary className="mt_20 w-100" onClick={handleSubmit}>
              Submit
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

export default EnterpriseForm;
